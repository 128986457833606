import { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { publicCommunityPostOptions } from '../../utils/publicPost';
import { DOG_VIDEOS } from '../DogParkVideo/DogParkVideos';
import { captureException } from '@sentry/react';

const DEFAULT_VIDEO_LENGTH_SECONDS = 180;

// Ensure compatibility with older WebKit-based browsers.
// Versions Chrome <23, Firefox <23, Safari <10 used webkitURL.
const URL = window.URL || window.webkitURL;

type VideoData = Record<
  string,
  {
    videoObjectUrl: string;
    fiHandle: string;
  }
>;

const downloadVideo = async (mp4Url: string) => {
  const response = await fetch(mp4Url);

  // Explicitly check if entire video has been downloaded.
  // Thrown error will be caught and new video will be fetched.
  if (response.status !== 200) {
    throw new Error('Failed to fetch complete video');
  }

  const videoBlob = await response.blob();
  const videoObjectUrl = URL.createObjectURL(videoBlob);

  const videoDuration = await getVideoDuration(videoObjectUrl);

  return { videoObjectUrl, videoDuration };
};

const clearVideos = (videoData: VideoData) => {
  for (const { videoObjectUrl } of Object.values(videoData)) {
    URL.revokeObjectURL(videoObjectUrl);
  }
};

const getVideoDuration = async (mp4Url: string): Promise<number> => {
  const videoElement = document.createElement('video');

  return new Promise((resolve, reject) => {
    videoElement.oncanplaythrough = () => {
      if (videoElement.duration === 0 || isNaN(videoElement.duration)) {
        reject(new Error('Invalid video duration'));
      } else {
        resolve(videoElement.duration);
      }
    };

    videoElement.onerror = () => {
      reject(new Error('Failed to load video metadata'));
    };

    videoElement.src = mp4Url;
  });
};

export const useVideoLoader = () => {
  const [videoData, setVideoData] = useState<VideoData>({});
  const [isAllLoaded, setIsAllLoaded] = useState(false);

  const queryClient = useQueryClient();

  // Given a target videoSeconds, retrieves videos from a preset list of dog video posts,
  // downloads them in order, and sets the videoData state.
  const processVideos = useCallback(
    async (videoSeconds: number) => {
      let totalDurationSeconds = 0;
      let data;
      let videoDurationSeconds: number;
      let videoObjectUrl: string;

      while (totalDurationSeconds < videoSeconds) {
        const videoId = getRandomVideoId(videoData);

        try {
          data = await queryClient.fetchQuery(publicCommunityPostOptions(videoId));
          if (data.post.type !== 'video' || !data.post.mp4Url) {
            throw new Error('Invalid post');
          }

          const videoData = await downloadVideo(data.post.mp4Url);
          videoDurationSeconds = videoData.videoDuration;
          videoObjectUrl = videoData.videoObjectUrl;
        } catch (error) {
          captureException(error, { extra: { videoId } });
          // Cooldown after error with video, wait 1 second before trying again.
          await new Promise((resolve) => setTimeout(resolve, 1000));
          continue;
        }

        totalDurationSeconds += videoDurationSeconds;

        const fiHandle = data.pet.fiHandle || 'Unknown';

        setVideoData((prevData) => ({
          ...prevData,
          [videoId]: { videoObjectUrl: videoObjectUrl, fiHandle: fiHandle },
        }));
      }
    },
    [videoData, queryClient],
  );

  const loadVideos = useCallback(
    async (videoSeconds: number = DEFAULT_VIDEO_LENGTH_SECONDS) => {
      setIsAllLoaded(false);
      clearVideos(videoData);
      setVideoData({});

      await processVideos(videoSeconds);
      setIsAllLoaded(true);
    },
    [processVideos, videoData],
  );

  return {
    loadVideos,
    videoData,
    isAllLoaded,
  };
};

// Get a random unused video id, if the id is already used, call the function again
const getRandomVideoId = (videoData: VideoData, iterationCount = 0): string => {
  const randomDogVideoIndex = Math.floor(Math.random() * DOG_VIDEOS.length);
  const dogVideoId = DOG_VIDEOS[randomDogVideoIndex];

  if (iterationCount === 5) {
    return dogVideoId;
  }

  if (videoData[dogVideoId]) {
    return getRandomVideoId(videoData, iterationCount + 1);
  }

  return dogVideoId;
};
