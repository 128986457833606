import isOnline from 'is-online';
import { useState, useEffect, useCallback } from 'react';

export const useSecondsPassed = (seconds: number) => {
  const [isTimePassed, setIsTimePassed] = useState(false);
  const [remainingTime, setRemainingTime] = useState(seconds);

  useEffect(() => {
    if (remainingTime <= 0) {
      setIsTimePassed(true);
      return;
    }

    const timer = setInterval(() => {
      setRemainingTime((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setIsTimePassed(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [remainingTime]);

  return isTimePassed;
};

const useCountdown = (intervalSeconds: number, onComplete: () => void) => {
  const [countdown, setCountdown] = useState(intervalSeconds);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setCountdown(intervalSeconds);
      onComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  return countdown;
};

const TEN_SECONDS_INTERVAL = 10;

export const useIsConnected = () => {
  const [isConnected, setIsConnected] = useState(true); // Assume true at first page load

  useCountdown(TEN_SECONDS_INTERVAL, async () => {
    const online = await isOnline();
    setIsConnected(online);
  });

  return isConnected;
};

export const useIsVideoReadyToPlay = (videoElement: HTMLVideoElement | null, checkOnce: boolean = false) => {
  const [isVideoReadyToPlay, setIsVideoReadyToPlay] = useState(false);

  const updateReadyState = useCallback(() => {
    if (!videoElement) return;

    // Determine if the video element has enough frames to play without buffering.
    setIsVideoReadyToPlay(videoElement?.readyState >= 3);
    if (checkOnce) {
      videoElement.removeEventListener('canplay', updateReadyState);
      videoElement.removeEventListener('canplaythrough', updateReadyState);
    }
  }, [videoElement, checkOnce]);

  useEffect(() => {
    if (!videoElement) return;

    updateReadyState();

    videoElement.addEventListener('canplay', updateReadyState);
    videoElement.addEventListener('canplaythrough', updateReadyState);

    return () => {
      videoElement.removeEventListener('canplay', updateReadyState);
      videoElement.removeEventListener('canplaythrough', updateReadyState);
    };
  }, [videoElement, updateReadyState]);

  return { isVideoReadyToPlay, setIsVideoReadyToPlay };
};
