import React, { createContext, useCallback } from 'react';
import { useVideoLoader } from './useVideoLoader'; // Import the custom hook
import { useState } from 'react';
import { ConnectedDogParkLeaderboard } from '../DogParkLeaderboard/DogParkLeaderboard';
import DogParkVideo from '../DogParkVideo/DogParkVideo';
import DogParkJoinCTA from '../DogParkJoinCTA/DogParkJoinCTA';
import { useSecondsPassed } from '../../lib/helpers/leaderboard/utils';
export interface DogParkScreenContextType {
  loadVideos: (videoSeconds: number) => Promise<void>;
  videoData: Record<string, any>;
  currentScreen: React.ReactNode;
  iterateScreenIndex: (indexOverride?: number) => void;
  isAllLoaded: boolean;
  isTimeToReload: boolean;
}

export const SECONDS_PER_PAGE_RELOAD = 10 * 60; // 10 minutes

export const DogParkScreenContext = createContext<DogParkScreenContextType | undefined>(undefined);

export const DogParkScreenContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { loadVideos, videoData, isAllLoaded } = useVideoLoader();

  const isTimeToReload = useSecondsPassed(SECONDS_PER_PAGE_RELOAD);

  const iterateScreenIndex = useCallback((indexOverride?: number) => {
    if (indexOverride !== undefined) {
      setCurrentScreenIndex(indexOverride);
    } else {
      setCurrentScreenIndex((prevIndex) => prevIndex + 1);
    }
  }, []);

  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
  const subScreens = [<ConnectedDogParkLeaderboard />, <DogParkVideo />, <DogParkJoinCTA />];
  const currentScreen = subScreens[currentScreenIndex];

  return (
    <DogParkScreenContext.Provider
      value={{ loadVideos, videoData, currentScreen, iterateScreenIndex, isAllLoaded, isTimeToReload }}
    >
      {children}
    </DogParkScreenContext.Provider>
  );
};
